import React, { useState } from "react";
import neptImg from "../Assets/images/products/aqua/product-1.JPG";
import geoImg from "../Assets/images/products/aqua/product-2.jpg";
import doEnImg from "../Assets/images/products/aqua/product-3.jpg";
import immunoImg from "../Assets/images/products/aqua/product-4.jpg";
import prodImg from '../Assets/images/products/aqua/product-5.jpg';
import prodImg2 from '../Assets/images/products/aqua/product-6.jpg';
import prodImg3 from '../Assets/images/products/aqua/product-7.jpg';
import prodImg4 from '../Assets/images/products/aqua/product-8.jpg';
import shrimpPointers from "../Assets/logo/shrimp.png";

const Products = () => {
  const [visibleSection, setVisibleSection] = useState("section1");

  const toggleVisibility = (section) => {
    setVisibleSection(section);
  };

  return (
    <section className="" id="products">
      <div>
        <div className="mt-12 flex items-center">
          <div className="hidden md:flex absolute bg-[#41ADEE] p-52 mt-16 rounded-r-3xl"></div>
          <div className="flex flex-wrap items-center mt-6 md:text-left text-center p-2 ">
            <div className="w-full md:w-3/5 lg:w-1/2">
              <img
                src={neptImg}
                alt="img"
                className="relative md:ml-10 h-[340px] w-[600px] rounded-2xl inline-block shadow-lg"/>
            </div>
            <div className="w-full md:w-2/5 lg:w-1/2 text-center md:text-left lg:pl-14">
              <h3 className="font-extrabold text-green-800 mt-2 md:mt-6 text-2xl sm:text-2xl">
                NEPTUNE
              </h3>
              <p className="text-md mt-1 text-[#718096] md:mt-2">
                Neptune is to improve the water quality in Aquaculture Farming
                is the application of probiotic microorganism to the pond.
                Neptune is a Nano Technology based enzyme in Liquid form it
                involves manipulation of bacteria in the ponds to reduce the
                pathogens. The synergistic and potent combination of
                concentrated enzymes leads to a strong improvement in digesting
                the feed consumed by shrimp.
              </p>
              <h3 className="mt-2 font-bold text-xl text-[#1D2A4D] uppercase">
                Advantages
              </h3>
              <ul className="mt-2 text-md">
                <li className="mt-2">
                  <img  src={shrimpPointers} alt="" className="inline h-5" />{" "}
                  Inhibition of Pathogens
                </li>
                <li className="mt-2">
                  <img  src={shrimpPointers} alt="" className="inline h-5" />{" "}
                  Maintains the optimum pH Level in water.
                </li>
                <li className="mt-2">
                  <img  src={shrimpPointers} alt="" className="inline h-5" />{" "}
                  Helps in stress tolerance.
                </li>
                <li className="mt-2">
                  <img  src={shrimpPointers} alt="" className="inline h-5" />{" "}
                  Improves overall Water Quality.
                </li>
                <li className="mt-2">
                  <img  src={shrimpPointers} alt="" className="inline h-5" /> Act
                  as growth promoter.
                </li>
              </ul>
              <div>
                <h3 className="mt-2 font-bold text-xl text-[#1D2A4D] uppercase">
                  COMPOSITION
                </h3>
                <p>
                  Bacillus Megaterium -1x10<sup>10</sup>, Lactobacillus-1x10<sup>10</sup>,
                  Rhodobacter-2x10<sup>10</sup> Mixture of Water Probiotic Microorganism
                  and Natural Extracts.
                </p>
                <h3 className="mt-2 font-bold text-xl text-[#1D2A4D] uppercase">
                  DOSAGE
                </h3>
                <p>
                  1 litre of Neptune / Acre/7 days or Advised by aqua Technician
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="md:p-12 flex justify-center items-center">
          <div className="hidden md:flex absolute bg-[#41ADEE] p-52 -mt-2 rounded-l-3xl right-0"></div>
          <div className="flex flex-wrap items-center mt-6  rounded-lg  text-center">
            <div className="w-full md:w-3/5 lg:w-1/2">
              <img
                src={geoImg}
                alt="light"
                class="relative h-[340px] w-[600px] inline-block rounded-2xl shadow-lg"
              />
            </div>
            <div class="w-full md:w-2/5 lg:w-1/2 md:order-first text-center md:text-left lg:pr-14">
              <h3 class="text-green-800 font-bold mt-2 md:mt-6 text-2xl sm:text-2xl">
                GEO SOIL PRO
              </h3>
              <p class="text-md  mt-1 text-[#718096] md:mt-4">
                The Probiotic used in Aquaculture is a live microbial addition,
                supplied via pond application and through feed which yields
                beneficial effects by modifying the gut micro flora by enhancing
                feed absorption of nutrition, Immunity against the pathogenic
                bacteria in gut. Soil quality maintains in the culture ponds by
                addition of gram positive bacillus strains than gram negative as
                they convert organic matter into carbon dioxide efficiently.{" "}
              </p>
              <h3 className="mt-2 font-bold text-xl text-[#1D2A4D] uppercase">
                ADVANTAGES
              </h3>
              <ul className="mt-2">
                <li className="mt-2">
                  <img  src={shrimpPointers} alt="" className="inline h-5" />{" "}
                  Organic Matter Degradation
                </li>
                <li className="mt-2">
                  <img  src={shrimpPointers} alt="" className="inline h-5" />{" "}
                  Hydrogen sulphide Reduction
                </li>
                <li className="mt-2">
                  <img  src={shrimpPointers} alt="" className="inline h-5" />{" "}
                  Support to improve the health of Aquaculture Species.
                </li>
                <li className="mt-2">
                  <img  src={shrimpPointers} alt="" className="inline h-5" />{" "}
                  Produces useful metabolites to stimulate growth.
                </li>
              </ul>
              <div>
                <h3 className="mt-2 font-bold text-xl text-[#1D2A4D] uppercase">
                  COMPOSITION
                </h3>
                <p>
                  Lactobacillus-1x10<sup>10</sup> , Rhodobacter-2x10<sup>10</sup> , Saccharomyces
                  cerevisiae, Bacillus and Lactobacillus.
                </p>
                <h3 className="mt-2 font-bold text-xl text-[#1D2A4D] uppercase">
                  DOSAGE
                </h3>
                <p>
                  1 litre of Neptune / Acre/7 days or Advised by aqua
                  Technician.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-12 flex  items-center">
          <div className="hidden md:flex absolute bg-[#41ADEE] p-52 mt-16 rounded-r-3xl"
          ></div>
          <div className="flex flex-wrap items-center mt-6 md:text-left text-center p-2 ">
            <div className="w-full md:w-3/5 lg:w-1/2" >
              <img
                src={doEnImg}
                alt="img"
                className="relative md:ml-10 h-[340px] w-[600px] rounded-2xl inline-block shadow-lg"
              />
            </div>
            <div className="w-full md:w-2/5 lg:w-1/2 text-center md:text-left lg:pl-14">
              <h3 className="font-extrabold text-green-800 mt-2 md:mt-6 text-2xl sm:text-2xl">
                IMMUNE
              </h3>
              <p className="text-md mt-1 text-[#718096] md:mt-2">
                Immunizer is an Enzyme and vitamin consortium product, Enzyme
                supplementation in aquaculture nutrition reduces feeding costs,
                allows flexibility on diets formulation, enhances performance
                and litter quality, improves shrimp health, and reduces total
                production costs.
              </p>
              <h3 className="mt-2 font-bold text-xl text-[#1D2A4D] uppercase">
                ADVANTAGES
              </h3>
              <ul className="mt-2 text-md">
                <li className="mt-2">
                  <img  src={shrimpPointers} alt="" className="inline h-5" />{" "}
                  Improve nutrient digestibility and growth performance.
                </li>
                <li className="mt-2">
                  <img  src={shrimpPointers} alt="" className="inline h-5" /> It
                  is added to soybean diet then improves weight gain and Feed
                </li>
                <li className="mt-2">
                  <img  src={shrimpPointers} alt="" className="inline h-5" />{" "}
                  Helps in quick for optimal wound repair
                </li>
              </ul>
              <div>
                <h3 className="mt-2 font-bold text-xl text-[#1D2A4D] uppercase">
                  COMPOSITION
                </h3>
                <p>Fermented Enzymes,Vitamins A and C</p>
                <h3 className="mt-2 font-bold text-xl text-[#1D2A4D] uppercase">
                  DOSAGE
                </h3>
                <p>3-5ml per Kilograms of feed.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="md:p-12 flex justify-center items-center">
          <div className="hidden md:flex absolute bg-[#41ADEE] p-52 -mt-2 rounded-l-3xl right-0"></div>
          <div className="flex flex-wrap items-center mt-6  rounded-lg  text-center">
            <div className="w-full md:w-3/5 lg:w-1/2">
              <img
                src={immunoImg}
                alt="light"
                class="relative h-[340px] w-[600px] inline-block rounded-2xl shadow-lg"
              />
            </div>
            <div class="w-full md:w-2/5 lg:w-1/2 md:order-first text-center md:text-left lg:pr-14">
              <h3 className="font-extrabold text-green-800 mt-2 md:mt-6 text-2xl sm:text-2xl">MINZONE
              </h3>
              <p className="text-md mt-1 text-[#718096] md:mt-2">
                Minerals play a significant role in the physiological functions to maintain and regulate
                the blood pH. It also play an important role in maintaining the osmoregulation. Minerals help
                in catalysing the biochemical reaction. Minerals provide good resistance towards the disease.
                Calcium and Magnesium are primary minerals required for new shell formation and moulting
                in shrimps.
              </p>
              <h3 className="mt-2 font-bold text-xl text-[#1D2A4D] uppercase">
                ADVANTAGES
              </h3>
              <ul className="mt-2 text-md">
                <li className="mt-2"> <img  src={shrimpPointers} alt="" className="inline h-5" />{" "}Stable Metabolism</li>
                <li className="mt-2"> <img  src={shrimpPointers} alt="" className="inline h-5" />{" "}Disease Resistance</li>
                <li className="mt-2">  <img  src={shrimpPointers} alt="" className="inline h-5" />{" "}It Supports to Improve the Survival rate of the shrimp</li>
                <li className="mt-2"> <img  src={shrimpPointers} alt="" className="inline h-5" />{" "}It helps Shrimp’s Skeleton Formation</li>
                <li className="mt-2"> <img  src={shrimpPointers} alt="" className="inline h-5" />{" "}HZ-MIN also contribute to Osmoregulation</li>
                <li className="mt-2"> <img  src={shrimpPointers} alt="" className="inline h-5" />{" "}Prevent the imbalance of mineral ions in the water</li>
              </ul>
              <div>
                <h3 className="mt-2 font-bold text-xl text-[#1D2A4D] uppercase">
                  COMPOSITION
                </h3>
                <p>
                  Calcium: 18.5%, Phosphorous: 6%, Magnesium: 2000mg, Potassium: 200mg,
                  Sodium: 8 mg, Sulphur: 0.9%, Chloride: 10mg, Manganese: 1000mg, Copper: 1800mg,
                  Cobalt: 100mg, Iron: 1000mg, Zinc: 2000mg, Iodine: 128mg, Selenium: 10mg, Aluminium:
                  1000mg and blended with Microorganisms
                </p>
                <h3 className="mt-2 font-bold text-xl text-[#1D2A4D] uppercase">
                  DOSAGE
                </h3>
                <ul>
                  <li> In pond preparation: 20- 25 kg/ha</li>
                  <li>During culture: 10 kg/ha once in every 15 days to provide a nutrient balance in pondwater</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-12 flex  items-center">
          <div className="hidden md:flex absolute bg-[#41ADEE] p-52 mt-16 rounded-r-3xl"></div>
          <div className="flex flex-wrap items-center mt-6 md:text-left text-center p-2 ">
            <div className="w-full md:w-3/5 lg:w-1/2">
              <img
                src={prodImg}
                alt="img"
                className="relative md:ml-10 h-[340px] w-[600px] rounded-2xl inline-block shadow-lg"
              />
            </div>
            <div className="w-full md:w-2/5 lg:w-1/2 text-center md:text-left lg:pl-14">
              <h3 className="font-extrabold text-green-800 mt-2 md:mt-6 text-2xl sm:text-2xl">
                OXYZON
              </h3>
              <p className="text-md mt-1 text-[#718096] md:mt-2">
                The success of aquaculture operations is in maintaining the
                pond, water and Soil quality to highest ideal Standards.
                Dissolved oxygen is the critical parameter needing the out most
                attention of The Aqua culturist. Shrimp spend most of the time
                at the pond bottom as such any low levels of Do can Cause
                stunted growth Higher susceptibility to disease, Mal nutrition
                and even immediate mortality Do enhancer to improve the
                Dissolved Oxygen level in the Aquaculture pond.
              </p>
              <h3 className="mt-2 font-bold text-xl text-[#1D2A4D] uppercase">
                ADVANTAGES
              </h3>
              <ul className="mt-2 text-md">
                <li className="mt-2">
                  <img  src={shrimpPointers} alt="" className="inline h-5" /> To
                  controls the growth of anaerobic bacteria.
                </li>
                <li className="mt-2">
                  <img  src={shrimpPointers} alt="" className="inline h-5" />
                  Changes anaerobic environment of the pond bottom to aerobic
                  one.
                </li>
                <li className="mt-2">
                  <img  src={shrimpPointers} alt="" className="inline h-5" /> To
                  provide sufficient stable and sustained release of oxygen.
                </li>
              </ul>
              <div>
                <h3 className="mt-2 font-bold text-xl text-[#1D2A4D] uppercase">
                  COMPOSITION
                </h3>
                <p>
                  Oxygen Producing Microorganism, Oxygen Stabilizing
                  Microorganism and Fermented enzymes with natural extract.
                </p>
                <h3 className="mt-2 font-bold text-xl text-[#1D2A4D] uppercase">
                  DOSAGE
                </h3>
                <p>1 litre per acre of culture pond.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="md:p-12 flex justify-center items-center">
          <div className="hidden md:flex absolute bg-[#41ADEE] p-52 -mt-2 rounded-l-3xl right-0"
          ></div>
          <div className="flex flex-wrap items-center mt-6  rounded-lg  text-center">
            <div className="w-full md:w-3/5 lg:w-1/2">
              <img
                src={prodImg2}
                alt="light"
                class="relative h-[340px] w-[600px] inline-block rounded-2xl shadow-lg" />
            </div>
            <div class="w-full md:w-2/5 lg:w-1/2 md:order-first text-center md:text-left lg:pr-14">
              <h3 class="text-green-800 font-bold mt-2 md:mt-6 text-2xl sm:text-2xl">
                NEUTRO
              </h3>
              <p class="text-md mt-1 text-[#718096] md:mt-4">
                It is a combination of bacterial strains with effective
                microorganisms it significantly improves the soil and water
                quality in the system. It enhancing rapid decomposition of waste
                in the Bottom under aerobic and anaerobic conditions.{" "}
              </p>
              <h3 className="mt-2 font-bold text-xl text-[#1D2A4D] uppercase">
                ADVANTAGES
              </h3>
              <ul className="mt-2">
                <li className="mt-2">
                  <img  src={shrimpPointers} alt="" className="inline h-5" />{" "}
                  Reduces sludge build up
                </li>
                <li className="mt-2">
                  <img  src={shrimpPointers} alt="" className="inline h-5" />{" "}
                  Reduces the Alkalinity.
                </li>
                <li className="mt-2">
                  <img  src={shrimpPointers} alt="" className="inline h-5" /> It
                  Helps to improve the stress resistance of the culture.
                </li>
                <li className="mt-2">
                  <img  src={shrimpPointers} alt="" className="inline h-5" /> It
                  helps Shrimp’s Skeleton Formation
                </li>
              </ul>
              <div>
                <h3 className="mt-2 font-bold text-xl text-[#1D2A4D] uppercase">
                  COMPOSITION
                </h3>
                <p>Algae with natural Extracts and Bacillus Microorganism</p>
                <h3 className="mt-2 font-bold text-xl text-[#1D2A4D] uppercase">
                  DOSAGE
                </h3>
                <p>2 litres per acre or Advised by Aqua consultant.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-12 flex  items-center">
          <div className="hidden md:flex absolute bg-[#41ADEE] p-52 mt-16 rounded-r-3xl"></div>
          <div className="flex flex-wrap items-center mt-6 md:text-left text-center p-2 ">
            <div className="w-full md:w-3/5 lg:w-1/2">
              <img
                src={prodImg3}
                alt="img"
                className="relative md:ml-10 h-[340px] w-[600px] rounded-2xl inline-block shadow-lg"
              />
            </div>
            <div className="w-full md:w-2/5 lg:w-1/2 text-center md:text-left lg:pl-14">
              <h3 className="font-extrabold text-green-800 mt-2 md:mt-6 text-2xl sm:text-2xl">
                BIO CLUSTER
              </h3>
              <p className="text-md mt-1 text-[#718096] md:mt-2">
                BIO CLUSTER is a viable Composition of Multi bacteria Enzyme for
                sludge degradation and plankton stabilisation in aquaculture
                ponds. It is a bio remediation technology, which involves
                manipulation of microorganisms in the ponds to reduce pathogenic
                bacteria, and to enhance mineralization of organic matter and to
                eliminate undesirable waste compounds.
              </p>
              <h3 className="mt-2 font-bold text-xl text-[#1D2A4D] uppercase">
                ADVANTAGES
              </h3>
              <ul className="mt-2 text-md">
                <li className="mt-2">
                  <img  src={shrimpPointers} alt="" className="inline h-5" />{" "}
                  Maintains stable bloom
                </li>
                <li className="mt-2">
                  <img  src={shrimpPointers} alt="" className="inline h-5" />
                  Degrades organic matter and keeps pond bottom clean
                </li>
                <li className="mt-2">
                  <img  src={shrimpPointers} alt="" className="inline h-5" />{" "}
                  Digest the feacal matter
                </li>
                <li className="mt-2">
                  <img  src={shrimpPointers} alt="" className="inline h-5" />{" "}
                  Reduction of Hydrogen Sulfide, Ammonia & Nitrite
                </li>
                <li className="mt-2">
                  <img  src={shrimpPointers} alt="" className="inline h-5" />
                  Stabilizes pH and reduces the risk of Pathogens
                </li>
                <li className="mt-2">
                  <img  src={shrimpPointers} alt="" className="inline h-5" />{" "}
                  Helps to Increase the Survival rate
                </li>
                <li className="mt-2">
                  <img  src={shrimpPointers} alt="" className="inline h-5" />
                  Works in a high range of pH
                </li>
                <li className="mt-2">
                  <img  src={shrimpPointers} alt="" className="inline h-5" />{" "}
                  Produces useful metabolites to stimulate growth.
                </li>
                <li className="mt-2">
                  <img  src={shrimpPointers} alt="" className="inline h-5" />
                  Improves feed intake and digestion.
                </li>
                <li className="mt-2">
                  <img  src={shrimpPointers} alt="" className="inline h-5" />{" "}
                  Improves FCR.
                </li>
              </ul>
              <div>
                <h3 className="mt-2 font-bold text-xl text-[#1D2A4D] uppercase">
                  COMPOSITION
                </h3>
                <p>
                  Optimum cfu of Bacillus Sp, Rhodobacter, Lactobacillus,
                  Bacillus Licheniformis and combination of Enzymes with
                  Probiotic media.
                </p>
                <h3 className="mt-2 font-bold text-xl text-[#1D2A4D] uppercase">
                  DOSAGE
                </h3>
                <p>
                  Direct application of 500ml/Acre of Cultured Pond or Advised
                  by Aquaculture Consultant.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="md:p-12 flex justify-center items-center">
          <div className="hidden md:flex absolute bg-[#41ADEE] p-52 -mt-2 rounded-l-3xl right-0"></div>
          <div className="flex flex-wrap items-center mt-6  rounded-lg  text-center">
            <div className="w-full md:w-3/5 lg:w-1/2">
              <img
                src={prodImg4}
                alt="light"
                class="relative h-[340px] w-[600px] inline-block rounded-2xl shadow-lg"
              />
            </div>
            <div class="w-full md:w-2/5 lg:w-1/2 md:order-first text-center md:text-left lg:pr-14">
              <h3 class="text-green-800 font-bold mt-2 md:mt-6 text-2xl sm:text-2xl">
                PROZONE
              </h3>
              <p class="text-md mt-1 text-[#718096] md:mt-4">
                Proteins that are involved in all anabolic and catabolic
                pathways of digestion and metabolism. Exogenous enzymes are
                administered in diets to make diet formulation more flexible.{" "}
              </p>
              <h3 className="mt-2 font-bold text-xl text-[#1D2A4D] uppercase">
                ADVANTAGES
              </h3>
              <ul className="mt-2">
                <li className="mt-2">
                  <img  src={shrimpPointers} alt="" className="inline h-5" /> To
                  reduce production cost,
                </li>
                <li className="mt-2">
                  <img  src={shrimpPointers} alt="" className="inline h-5" />{" "}
                  Decrease digest a viscosity, to enhance nutrients digestion,
                </li>
                <li className="mt-2">
                  <img  src={shrimpPointers} alt="" className="inline h-5" /> It
                  To improve Apparent Metabolizable Energy value of the diet, to
                  increase feed intake,weight gain.
                </li>
                <li className="mt-2">
                  <img  src={shrimpPointers} alt="" className="inline h-5" /> It
                  To reduce beak impaction and vent plugging
                </li>
                <li className="mt-2">
                  <img  src={shrimpPointers} alt="" className="inline h-5" /> It
                  To alter population of gut microorganisms.
                </li>
                <li className="mt-2">
                  <img  src={shrimpPointers} alt="" className="inline h-5" /> It
                  To improve the nutritional value of diets and the subsequent
                  performance.
                </li>
              </ul>
              <div>
                <h3 className="mt-2 font-bold text-xl text-[#1D2A4D] uppercase">
                  COMPOSITION
                </h3>
                <p>Natural Extracts, Enzymes, Amino Acids.</p>
                <h3 className="mt-2 font-bold text-xl text-[#1D2A4D] uppercase">
                  DOSAGE
                </h3>
                <p>2-4ml per Kilograms of feed</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Products;
